<template>
  <VueShowdown
    v-bind="$attrs"
    class="Markdown"
    :class="{ hasStyles }"
    :markdown="markdown" />
</template>

<script>
import Vue from 'vue'
import VueShowdown from 'vue-showdown'

Vue.use(VueShowdown, {
  options: {
    tables: true,
  },
})

export default {
  props: {
    hasStyles: Boolean,
    markdown: String,
  },
}
</script>

<style lang="scss">
.Markdown.hasStyles {
  h1 {
    font-size: $h3;
    margin-bottom: 2rem;
    @include md {
      font-size: $h2;
    }
  }

  h2 {
    font-size: $h3;
    margin: 3rem 0;
  }

  h3 {
    font-size: $h35;
  }

  h4 {
    font-weight: $semibold;
  }

  h3,
  h4,
  ul {
    margin: 3rem 0 1rem;
  }

  ul {
    margin-left: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  table {
    border: 1px solid $border-color;
    border-radius: $radius;
    margin: 2rem 0;

    th {
      background: $main;
      border-radius: $radius;
      color: $white;
    }

    td,
    th {
      padding: 1rem;
    }
  }

  img {
    // margin-left: -1rem;
    max-width: 100%;
  }

  .youtubeContainer {
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;

    > iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
</style>
